import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "@firebolt-dev/jsx/jsx-runtime";
import {cloneElement} from 'react';
import {useMDXComponents} from 'firebolt';
const MDXLayout = function MDXWrapper({children}) {
  const components = useMDXComponents();
  return cloneElement(children, {
    components
  });
};
function _createMdxContent(props) {
  const _components = {
    a: "a",
    code: "code",
    h1: "h1",
    h2: "h2",
    p: "p",
    pre: "pre",
    span: "span",
    ...props.components
  }, {Meta} = _components;
  if (!Meta) _missingMdxReference("Meta", true);
  return _jsxs(_Fragment, {
    children: [_jsx(Meta, {
      title: "Getting Started: Routes",
      description: "Learn how to add routes to a new Firebolt app."
    }), "\n", _jsx(_components.h1, {
      children: "Routes"
    }), "\n", _jsxs(_components.p, {
      children: ["The ", _jsx(_components.code, {
        children: "routes"
      }), " directory is an intuitive yet unique way of organizing all of your static and dynamic content together in a single folder."]
    }), "\n", _jsx(_components.h2, {
      children: "Pages"
    }), "\n", _jsxs(_components.p, {
      children: ["You can add a page to your app by creating a ", _jsx(_components.code, {
        children: ".js"
      }), " file that exports a default React component"]
    }), "\n", _jsx(_components.pre, {
      className: "shiki shiki-themes github-light github-dark",
      style: {
        "--shiki-light": "#24292e",
        "--shiki-dark": "#e1e4e8",
        "--shiki-light-bg": "#fff",
        "--shiki-dark-bg": "#24292e"
      },
      tabIndex: "0",
      title: "routes/about.js",
      lineNumbers: true,
      children: _jsxs(_components.code, {
        children: [_jsxs(_components.span, {
          className: "line",
          children: [_jsx(_components.span, {
            style: {
              "--shiki-light": "#D73A49",
              "--shiki-dark": "#F97583"
            },
            children: "export"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#D73A49",
              "--shiki-dark": "#F97583"
            },
            children: " default"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#D73A49",
              "--shiki-dark": "#F97583"
            },
            children: " function"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#6F42C1",
              "--shiki-dark": "#B392F0"
            },
            children: " About"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "() {"
          })]
        }), "\n", _jsxs(_components.span, {
          className: "line",
          children: [_jsx(_components.span, {
            style: {
              "--shiki-light": "#D73A49",
              "--shiki-dark": "#F97583"
            },
            children: "  return"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: " ("
          })]
        }), "\n", _jsxs(_components.span, {
          className: "line",
          children: [_jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "    <"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#22863A",
              "--shiki-dark": "#85E89D"
            },
            children: "main"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: ">"
          })]
        }), "\n", _jsxs(_components.span, {
          className: "line",
          children: [_jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "      <"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#22863A",
              "--shiki-dark": "#85E89D"
            },
            children: "h1"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: ">About Us</"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#22863A",
              "--shiki-dark": "#85E89D"
            },
            children: "h1"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: ">"
          })]
        }), "\n", _jsxs(_components.span, {
          className: "line",
          children: [_jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "      <"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#22863A",
              "--shiki-dark": "#85E89D"
            },
            children: "p"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: ">...</"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#22863A",
              "--shiki-dark": "#85E89D"
            },
            children: "p"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: ">"
          })]
        }), "\n", _jsxs(_components.span, {
          className: "line",
          children: [_jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "    </"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#22863A",
              "--shiki-dark": "#85E89D"
            },
            children: "main"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: ">"
          })]
        }), "\n", _jsx(_components.span, {
          className: "line",
          children: _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "  )"
          })
        }), "\n", _jsx(_components.span, {
          className: "line",
          children: _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "}"
          })
        }), "\n", _jsx(_components.span, {
          className: "line"
        })]
      })
    }), "\n", _jsxs(_components.p, {
      children: ["This page is rendered when you visit ", _jsx(_components.code, {
        children: "/about"
      }), "."]
    }), "\n", _jsx(_components.p, {
      children: "Pages are just regular React components with no magical named exports. You'll learn more about how to fetch and interact with data later on in this guide."
    }), "\n", _jsx(_components.h2, {
      children: "Dynamic Pages"
    }), "\n", _jsxs(_components.p, {
      children: ["You can create dynamic route segments by using a ", _jsx(_components.code, {
        children: "$"
      }), " variable in your file or folder name:"]
    }), "\n", _jsx(_components.pre, {
      className: "shiki shiki-themes github-light github-dark",
      style: {
        "--shiki-light": "#24292e",
        "--shiki-dark": "#e1e4e8",
        "--shiki-light-bg": "#fff",
        "--shiki-dark-bg": "#24292e"
      },
      tabIndex: "0",
      title: "routes/blog/$slug.js",
      lineNumbers: true,
      children: _jsxs(_components.code, {
        children: [_jsxs(_components.span, {
          className: "line",
          children: [_jsx(_components.span, {
            style: {
              "--shiki-light": "#D73A49",
              "--shiki-dark": "#F97583"
            },
            children: "import"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: " { useRoute } "
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#D73A49",
              "--shiki-dark": "#F97583"
            },
            children: "from"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#032F62",
              "--shiki-dark": "#9ECBFF"
            },
            children: " 'firebolt'"
          })]
        }), "\n", _jsx(_components.span, {
          className: "line"
        }), "\n", _jsxs(_components.span, {
          className: "line",
          children: [_jsx(_components.span, {
            style: {
              "--shiki-light": "#D73A49",
              "--shiki-dark": "#F97583"
            },
            children: "export"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#D73A49",
              "--shiki-dark": "#F97583"
            },
            children: " default"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#D73A49",
              "--shiki-dark": "#F97583"
            },
            children: " function"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#6F42C1",
              "--shiki-dark": "#B392F0"
            },
            children: " Post"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "() {"
          })]
        }), "\n", _jsxs(_components.span, {
          className: "line",
          children: [_jsx(_components.span, {
            style: {
              "--shiki-light": "#D73A49",
              "--shiki-dark": "#F97583"
            },
            children: "  const"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: " { "
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#005CC5",
              "--shiki-dark": "#79B8FF"
            },
            children: "slug"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: " } "
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#D73A49",
              "--shiki-dark": "#F97583"
            },
            children: "="
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#6F42C1",
              "--shiki-dark": "#B392F0"
            },
            children: " useRoute"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "().params"
          })]
        }), "\n", _jsxs(_components.span, {
          className: "line",
          children: [_jsx(_components.span, {
            style: {
              "--shiki-light": "#D73A49",
              "--shiki-dark": "#F97583"
            },
            children: "  return"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: " <"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#22863A",
              "--shiki-dark": "#85E89D"
            },
            children: "div"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: ">Slug: {slug}</"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#22863A",
              "--shiki-dark": "#85E89D"
            },
            children: "div"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: ">"
          })]
        }), "\n", _jsx(_components.span, {
          className: "line",
          children: _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "}"
          })
        }), "\n", _jsx(_components.span, {
          className: "line"
        })]
      })
    }), "\n", _jsxs(_components.p, {
      children: ["You can learn more about these and catch-all segments on the ", _jsx(_components.a, {
        href: "/docs/ref/page-js",
        children: "page.js"
      }), " reference."]
    }), "\n", _jsx(_components.h2, {
      children: "Layouts"
    }), "\n", _jsx(_components.p, {
      children: "Firebolt also supports nested layouts that maintain their state when switching between pages that share the same layout."
    }), "\n", _jsx(_components.pre, {
      className: "shiki shiki-themes github-light github-dark",
      style: {
        "--shiki-light": "#24292e",
        "--shiki-dark": "#e1e4e8",
        "--shiki-light-bg": "#fff",
        "--shiki-dark-bg": "#24292e"
      },
      tabIndex: "0",
      title: "routes/docs/_layout.js",
      lineNumbers: true,
      children: _jsxs(_components.code, {
        children: [_jsxs(_components.span, {
          className: "line",
          children: [_jsx(_components.span, {
            style: {
              "--shiki-light": "#D73A49",
              "--shiki-dark": "#F97583"
            },
            children: "export"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#D73A49",
              "--shiki-dark": "#F97583"
            },
            children: " default"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#D73A49",
              "--shiki-dark": "#F97583"
            },
            children: " function"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#6F42C1",
              "--shiki-dark": "#B392F0"
            },
            children: " Docs"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "({ "
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#E36209",
              "--shiki-dark": "#FFAB70"
            },
            children: "children"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: " }) {"
          })]
        }), "\n", _jsxs(_components.span, {
          className: "line",
          children: [_jsx(_components.span, {
            style: {
              "--shiki-light": "#D73A49",
              "--shiki-dark": "#F97583"
            },
            children: "  return"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: " ("
          })]
        }), "\n", _jsxs(_components.span, {
          className: "line",
          children: [_jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "    <"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#22863A",
              "--shiki-dark": "#85E89D"
            },
            children: "div"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: ">"
          })]
        }), "\n", _jsxs(_components.span, {
          className: "line",
          children: [_jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "      <"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#22863A",
              "--shiki-dark": "#85E89D"
            },
            children: "nav"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: ">{"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#6A737D",
              "--shiki-dark": "#6A737D"
            },
            children: "/* shared navigation */"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "}</"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#22863A",
              "--shiki-dark": "#85E89D"
            },
            children: "nav"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: ">"
          })]
        }), "\n", _jsx(_components.span, {
          className: "line",
          children: _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "      {children}"
          })
        }), "\n", _jsxs(_components.span, {
          className: "line",
          children: [_jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "    </"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#22863A",
              "--shiki-dark": "#85E89D"
            },
            children: "div"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: ">"
          })]
        }), "\n", _jsx(_components.span, {
          className: "line",
          children: _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "  )"
          })
        }), "\n", _jsx(_components.span, {
          className: "line",
          children: _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "}"
          })
        }), "\n", _jsx(_components.span, {
          className: "line"
        })]
      })
    }), "\n", _jsx(_components.pre, {
      className: "shiki shiki-themes github-light github-dark",
      style: {
        "--shiki-light": "#24292e",
        "--shiki-dark": "#e1e4e8",
        "--shiki-light-bg": "#fff",
        "--shiki-dark-bg": "#24292e"
      },
      tabIndex: "0",
      title: "routes/docs/intro.mdx",
      lineNumbers: true,
      children: _jsxs(_components.code, {
        children: [_jsx(_components.span, {
          className: "line",
          children: _jsx(_components.span, {
            style: {
              "--shiki-light": "#005CC5",
              "--shiki-dark": "#79B8FF",
              "--shiki-light-font-weight": "bold",
              "--shiki-dark-font-weight": "bold"
            },
            children: "# Intro"
          })
        }), "\n", _jsx(_components.span, {
          className: "line"
        }), "\n", _jsx(_components.span, {
          className: "line",
          children: _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "Let's get started..."
          })
        }), "\n", _jsx(_components.span, {
          className: "line"
        })]
      })
    }), "\n", _jsxs(_components.p, {
      children: ["In the example above, visiting ", _jsx(_components.code, {
        children: "/docs/intro"
      }), " will show the intro page nested inside the docs layout."]
    }), "\n", _jsxs(_components.p, {
      children: ["The root layout at ", _jsx(_components.code, {
        children: "routes/_layout.js"
      }), " is used to wrap your entire app with ", _jsx(_components.code, {
        children: "<html>"
      }), " and ", _jsx(_components.code, {
        children: "<body>"
      }), " tags."]
    }), "\n", _jsx(_components.h2, {
      children: "MDX"
    }), "\n", _jsxs(_components.p, {
      children: ["As you might have noticed we also support MDX pages out of the box, allowing you to write your content with super powered markdown. Simply use the ", _jsx(_components.code, {
        children: ".mdx"
      }), " extension."]
    }), "\n", _jsxs(_components.p, {
      children: ["You can ", _jsx(_components.a, {
        href: "/docs/ref/config",
        children: "configure"
      }), " MDX with plugins to provide syntax highlighting or support github flavored markdown, anything you need."]
    }), "\n", _jsxs(_components.p, {
      children: ["You can also customize the components rendered by MDX with an ", _jsx(_components.a, {
        href: "/docs/ref/page-mdx",
        children: "MDXProvider"
      })]
    }), "\n", _jsx(_components.h2, {
      children: "Static Files"
    }), "\n", _jsxs(_components.p, {
      children: ["The ", _jsx(_components.code, {
        children: "routes"
      }), " directory also serves all your static assets such as fonts and images. For example ", _jsx(_components.code, {
        children: "routes/robots.txt"
      }), " will be available at ", _jsx(_components.code, {
        children: "/robots.txt"
      }), "."]
    }), "\n", _jsx(_components.h2, {
      children: "Virtual Files"
    }), "\n", _jsxs(_components.p, {
      children: ["For dynamic files that need to be generated on the fly you can create virtual assets by giving them a ", _jsx(_components.code, {
        children: ".js"
      }), " extension and handling the appropriate HTTP method"]
    }), "\n", _jsx(_components.pre, {
      className: "shiki shiki-themes github-light github-dark",
      style: {
        "--shiki-light": "#24292e",
        "--shiki-dark": "#e1e4e8",
        "--shiki-light-bg": "#fff",
        "--shiki-dark-bg": "#24292e"
      },
      tabIndex: "0",
      title: "routes/robots.txt.js",
      lineNumbers: true,
      children: _jsxs(_components.code, {
        children: [_jsxs(_components.span, {
          className: "line",
          children: [_jsx(_components.span, {
            style: {
              "--shiki-light": "#D73A49",
              "--shiki-dark": "#F97583"
            },
            children: "export"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#D73A49",
              "--shiki-dark": "#F97583"
            },
            children: " function"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#6F42C1",
              "--shiki-dark": "#B392F0"
            },
            children: " get"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "("
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#E36209",
              "--shiki-dark": "#FFAB70"
            },
            children: "ctx"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: ") {"
          })]
        }), "\n", _jsxs(_components.span, {
          className: "line",
          children: [_jsx(_components.span, {
            style: {
              "--shiki-light": "#D73A49",
              "--shiki-dark": "#F97583"
            },
            children: "  const"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#005CC5",
              "--shiki-dark": "#79B8FF"
            },
            children: " data"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#D73A49",
              "--shiki-dark": "#F97583"
            },
            children: " ="
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#032F62",
              "--shiki-dark": "#9ECBFF"
            },
            children: " `"
          })]
        }), "\n", _jsx(_components.span, {
          className: "line",
          children: _jsx(_components.span, {
            style: {
              "--shiki-light": "#032F62",
              "--shiki-dark": "#9ECBFF"
            },
            children: "    User-agent: *"
          })
        }), "\n", _jsx(_components.span, {
          className: "line",
          children: _jsx(_components.span, {
            style: {
              "--shiki-light": "#032F62",
              "--shiki-dark": "#9ECBFF"
            },
            children: "    Disallow:"
          })
        }), "\n", _jsx(_components.span, {
          className: "line",
          children: _jsx(_components.span, {
            style: {
              "--shiki-light": "#032F62",
              "--shiki-dark": "#9ECBFF"
            },
            children: "  `"
          })
        }), "\n", _jsxs(_components.span, {
          className: "line",
          children: [_jsx(_components.span, {
            style: {
              "--shiki-light": "#D73A49",
              "--shiki-dark": "#F97583"
            },
            children: "  return"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#D73A49",
              "--shiki-dark": "#F97583"
            },
            children: " new"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#6F42C1",
              "--shiki-dark": "#B392F0"
            },
            children: " Response"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "(data, {"
          })]
        }), "\n", _jsx(_components.span, {
          className: "line",
          children: _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "    headers: {"
          })
        }), "\n", _jsxs(_components.span, {
          className: "line",
          children: [_jsx(_components.span, {
            style: {
              "--shiki-light": "#032F62",
              "--shiki-dark": "#9ECBFF"
            },
            children: "      'Content-Type'"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: ": "
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#032F62",
              "--shiki-dark": "#9ECBFF"
            },
            children: "'text/plain'"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: ","
          })]
        }), "\n", _jsx(_components.span, {
          className: "line",
          children: _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "    },"
          })
        }), "\n", _jsx(_components.span, {
          className: "line",
          children: _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "  })"
          })
        }), "\n", _jsx(_components.span, {
          className: "line",
          children: _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "}"
          })
        }), "\n", _jsx(_components.span, {
          className: "line"
        })]
      })
    }), "\n", _jsx(_components.p, {
      children: "This gives you full control over all of your content and can be useful for many things including sitemap generation, image transformation and open graph image generators."
    }), "\n", _jsx(_components.h2, {
      children: "API Routes"
    }), "\n", _jsxs(_components.p, {
      children: ["You can use the same mechanism to create API endpoints, eg ", _jsx(_components.code, {
        children: "GET /api/users"
      }), ":"]
    }), "\n", _jsx(_components.pre, {
      className: "shiki shiki-themes github-light github-dark",
      style: {
        "--shiki-light": "#24292e",
        "--shiki-dark": "#e1e4e8",
        "--shiki-light-bg": "#fff",
        "--shiki-dark-bg": "#24292e"
      },
      tabIndex: "0",
      title: "routes/api/users.js",
      lineNumbers: true,
      children: _jsxs(_components.code, {
        children: [_jsxs(_components.span, {
          className: "line",
          children: [_jsx(_components.span, {
            style: {
              "--shiki-light": "#D73A49",
              "--shiki-dark": "#F97583"
            },
            children: "export"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#D73A49",
              "--shiki-dark": "#F97583"
            },
            children: " function"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#6F42C1",
              "--shiki-dark": "#B392F0"
            },
            children: " get"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "("
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#E36209",
              "--shiki-dark": "#FFAB70"
            },
            children: "ctx"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: ") {"
          })]
        }), "\n", _jsxs(_components.span, {
          className: "line",
          children: [_jsx(_components.span, {
            style: {
              "--shiki-light": "#D73A49",
              "--shiki-dark": "#F97583"
            },
            children: "  return"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#D73A49",
              "--shiki-dark": "#F97583"
            },
            children: " await"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: " ctx."
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#6F42C1",
              "--shiki-dark": "#B392F0"
            },
            children: "db"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "("
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#032F62",
              "--shiki-dark": "#9ECBFF"
            },
            children: "'users'"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: ")"
          })]
        }), "\n", _jsx(_components.span, {
          className: "line",
          children: _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "}"
          })
        }), "\n", _jsx(_components.span, {
          className: "line"
        })]
      })
    }), "\n", _jsx(_components.p, {
      children: "As you'll see later on, unless your app explicitly needs to provide an API to its users, you won't even need these."
    }), "\n", _jsx(_components.h2, {
      children: "Summary"
    }), "\n", _jsxs(_components.p, {
      children: ["The ", _jsx(_components.code, {
        children: "routes"
      }), " folder is a simple yet powerful concept that allows you to keep your app organized, supporting essentially anything you might need."]
    }), "\n", _jsxs(_components.p, {
      children: ["When building components that need to be shared across multiple routes, you can place them outside of the ", _jsx(_components.code, {
        children: "routes"
      }), " directory in a ", _jsx(_components.code, {
        children: "components"
      }), " directory or similar, and pull them in as needed."]
    }), "\n", _jsxs(_components.p, {
      children: ["By the end of this guide you'll see that it's possible to build large complex SaaS applications that have little more than a ", _jsx(_components.code, {
        children: "routes"
      }), " and ", _jsx(_components.code, {
        children: "components"
      }), " directory. It's magic."]
    })]
  });
}
export default function MDXContent(props = {}) {
  return _jsx(MDXLayout, {
    ...props,
    children: _jsx(_createMdxContent, {
      ...props
    })
  });
}
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
